import {
  DOVER_LINKEDIN_COMPANY_ID,
  LINKEDIN_COMPANY_URL_PREFIX,
  LINKEDIN_COMPANY_URL_REGEX,
  LINKEDIN_PERSON_URL_REGEX,
} from "components/dover/constants";

export function getLinkedInIdFromUrl(url: string): string {
  // If we have the wrong format, then this shouldn't work
  const companyUrlRegexMatch = url.match(LINKEDIN_COMPANY_URL_REGEX);
  const personUrlRegexMatch = url.match(LINKEDIN_PERSON_URL_REGEX);
  const linkedinId = companyUrlRegexMatch
    ? companyUrlRegexMatch[1]
    : personUrlRegexMatch
    ? personUrlRegexMatch[1]
    : undefined;

  if (!linkedinId) {
    return "";
  }

  // Don't allow users to pass in Dover LinkedIn Company ID
  if (companyUrlRegexMatch && linkedinId === DOVER_LINKEDIN_COMPANY_ID) {
    return "";
  }

  return linkedinId;
}

export function getLinkedInUrlFromId(id: string | undefined | null): string {
  if (!id) {
    return "";
  }
  return LINKEDIN_COMPANY_URL_PREFIX + id + "/";
}
