import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { FC, useCallback, useMemo } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { StringParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as LigntningSVG } from "assets/icons/green-lightning.svg";
import { getLinkedInIdFromUrl } from "components/dover/CompanySetupBasicInfo/utils";
import { FileDropzone, nameLengthValidator } from "components/FileDropzone";
import { InfoTip } from "components/InfoTip";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonRadio } from "components/library/ButtonRadio";
import Drawer from "components/library/Drawer";
import { TextField } from "components/library/TextField";
import { BodySmall } from "components/library/typography";
import { Body, ButtonText, Subtitle2 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { appConfig } from "config/appConfig";
import { modalAtom, useModal } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import DrawerHeader from "sections/addCandidateV2/components/DrawerHeader";
import { AddCandidateJobSelector } from "sections/addCandidateV2/components/JobSelector";
import { FilePreview } from "sections/addCandidateV2/components/ResumeUploadBox";
import PipelineStageSelect from "sections/addCandidateV2/components/SelectPipelineStage";
import {
  InterviewPipelineCandidateInfoSchema,
  interviewPipelineCandidateInfoZodSchema,
} from "sections/addCandidateV2/types/candidateInfo";
import {
  useAddCandidateInitialOutreachInterviewMutation,
  useLazyCanSourceProfileQuery,
  useParseCandidateInfoMutation,
} from "services/doverapi/endpoints/candidate";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import {
  DashboardJob,
  HiringPipelineStageMilestone,
  HiringPipelineStageType,
  ParseCandidateInfoResult,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";

export const TWENTY_MEGA_BYTES = 20 * 1024 * 1024;

const CANDIDATE_SOURCE_CHOICES = [
  {
    internalName: "UNKNOWN",
    value: "UNKNOWN",
    label: "Other",
  },
  {
    internalName: "CANDIDATE_REFERRED",
    value: "CANDIDATE_REFERRED",
    label: "Referral",
  },
  {
    internalName: "AGENCY",
    value: "AGENCY",
    label: "Agency",
  },
];

interface ConfirmDrawerCloseDialogProps {
  confirmClose: () => void;
}
export const ConfirmDrawerCloseDialog: FC<GlobalModalProps & ConfirmDrawerCloseDialogProps> = ({
  isOpen,
  close,
  confirmClose,
}) => {
  return (
    <CustomModal
      open={isOpen}
      title={<Subtitle2>Are you sure?</Subtitle2>}
      onClose={close}
      maxWidth="sm"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <Button
            onClick={(): void => {
              confirmClose();
              close();
            }}
            variant={ButtonVariant.Secondary}
          >
            <ButtonText>Yes, close</ButtonText>
          </Button>
          <Button onClick={close} variant={ButtonVariant.Primary}>
            <ButtonText color="white">Cancel</ButtonText>
          </Button>
        </Stack>
      }
    >
      <Body>Closing will discard all of your changes.</Body>
    </CustomModal>
  );
};

export const confirmCloseModalAtom = modalAtom(ConfirmDrawerCloseDialog);

//------------------------------------------------------------------------------
// Main Component

export const AddCandidateV2Drawer: FC<GlobalModalProps> = ({ isOpen, close }) => {
  const jobIdFromUrl = useJobIdFromUrl();
  const [hpsIdFromUrl] = useQueryParam("hpsId", StringParam);

  const [jobId, setJobId] = React.useState<string | undefined>(jobIdFromUrl);
  const [hpsId, setHpsId] = React.useState<string | undefined>(hpsIdFromUrl ?? undefined);
  const [existingCandidateId, setExistingCandidateId] = React.useState<string | undefined>(undefined);

  const { open: showDismissDialog } = useModal(confirmCloseModalAtom);

  const navigate = useNavigate();
  const { activeJobs: unsortedJobs, jobsLoading } = useGetJobsDerivedData({});

  const job: DashboardJob | undefined = useMemo(() => unsortedJobs?.find(job => job.id === jobId), [
    unsortedJobs,
    jobId,
  ]);

  if (job && !hpsId) {
    const initialCallHpsId = job?.hiringPipelineStages?.find(
      hps => hps.milestone === HiringPipelineStageMilestone.INITIAL_CALL
    )?.id;
    setHpsId(initialCallHpsId);
  }

  const appliedHpsId = job?.hiringPipelineStages?.find(
    hps => hps.stageType === HiringPipelineStageType.APPLICATION_REVIEW
  )?.id;

  const [
    addCandidateToInterviewProcess,
    { isLoading: isAddingCandidate },
  ] = useAddCandidateInitialOutreachInterviewMutation();
  const [parseCandidateInfo, { isLoading: isParsingResume }] = useParseCandidateInfoMutation();
  const [canSourceProfile] = useLazyCanSourceProfileQuery();

  // RHF setup
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<InterviewPipelineCandidateInfoSchema>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      linkedinUrl: "",
      source: "UNKNOWN",
      agencyEmail: "",
      agencyName: "",
    },
    mode: "onBlur",
    resolver: zodResolver(interviewPipelineCandidateInfoZodSchema),
  });

  const { field: firstName } = useController({
    name: "firstName",
    control,
  });
  const { field: lastName } = useController({
    name: "lastName",
    control,
  });
  const { field: email } = useController({
    name: "email",
    control,
  });
  const { field: linkedinUrl } = useController({
    name: "linkedinUrl",
    control,
  });
  const { field: source } = useController({
    name: "source",
    control,
  });
  const { field: agencyName } = useController({
    name: "agencyName",
    control,
  });
  const { field: agencyEmail } = useController({
    name: "agencyEmail",
    control,
  });
  const { field: resumeField } = useController({
    name: "resume",
    control,
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]): void => {
      // This function still runs even if all files were rejeted
      if (acceptedFiles.length === 0) {
        return;
      }

      resumeField.onChange(acceptedFiles[0]);

      const parseResult = parseCandidateInfo({ resume: acceptedFiles[0] }).unwrap();
      parseResult.then((result: ParseCandidateInfoResult): void => {
        if (result.firstName) {
          firstName.onChange(result.firstName);
        }
        if (result.lastName && !lastName.value) {
          lastName.onChange(result.lastName);
        }
        if (result.email && !email.value) {
          email.onChange(result.email);
        }
        if (result.linkedinUrl && !linkedinUrl.value) {
          linkedinUrl.onChange(result.linkedinUrl);
        }
      });
    },
    [resumeField, parseCandidateInfo, firstName, lastName, email, linkedinUrl]
  );
  const formHasErrors = !!Object.keys(errors).length;
  const missingJobOrStageID = !jobId || !hpsId;

  const dropzoneOptions = useMemo(
    () => ({
      onDrop,
      maxFiles: 1,
      maxSize: TWENTY_MEGA_BYTES,
      validator: nameLengthValidator,
      accept: ".pdf",
    }),
    [onDrop]
  );

  useDebounce(
    () => {
      trigger(["email", "linkedinUrl"]).then(
        async (isValid: boolean): Promise<void> => {
          if (isValid && (!!email.value || !!linkedinUrl.value)) {
            const canSourceProfileResult = await canSourceProfile({
              data: {
                email: email.value ? email.value : undefined,
                jobId: jobId!,
                linkedinPublicId: linkedinUrl.value ? getLinkedInIdFromUrl(linkedinUrl.value) : undefined,
              },
            }).unwrap();
            if (canSourceProfileResult.candidateId) {
              setExistingCandidateId(canSourceProfileResult.candidateId);
            } else {
              setExistingCandidateId(undefined);
            }
          }
        }
      );
    },
    250,
    [errors, jobId, trigger, email.value, linkedinUrl.value, setExistingCandidateId]
  );

  const addCandidate: SubmitHandler<InterviewPipelineCandidateInfoSchema> = async (
    data: InterviewPipelineCandidateInfoSchema
  ): Promise<void> => {
    const addResponse = await addCandidateToInterviewProcess({
      job: jobId!,
      // Candidate info
      pipelineStage: hpsId!,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email ?? undefined,
      linkedinProfileUrl: data.linkedinUrl,
      resume: data.resume as Blob,
      sourceName: data.source,
      agencyName: data.agencyName ?? undefined,
      agencyEmail: data.agencyEmail ?? undefined,
    }).unwrap();

    if (addResponse.success) {
      close();
      const candidateId = addResponse?.candidate;
      if (candidateId) {
        navigate(APP_ROUTE_PATHS.candidates.candidateDetail(candidateId));
      }
    }
  };

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={(): void => {
        if (firstName.value || lastName.value || resumeField.value || email.value || linkedinUrl.value) {
          showDismissDialog({ confirmClose: close });
        } else {
          close();
        }
      }}
      sx={{ position: "relative", overflow: "auto" }}
    >
      <DrawerHeader />
      {isAddingCandidate ? (
        <Stack justifyContent={"center"} alignItems={"center"} height={"100%"} width={"100%"}>
          <DoverLoadingSpinner />
        </Stack>
      ) : (
        <Stack
          direction="column"
          spacing={2}
          width="100%"
          sx={{ p: 3, width: "100%", height: "100%", overflow: "auto" }}
        >
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
              <BodySmall weight="600">Resume</BodySmall>
              <BodySmall>(optional)</BodySmall>
            </Stack>
            {!resumeField.value ? (
              <Stack direction="column" spacing={0.8}>
                <FileDropzone options={dropzoneOptions} />
                <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
                  <LigntningSVG width="20px" height="20px" />
                  <BodySmall color={colors.grayscale.gray500}>Auto-fill candidate info from their resume</BodySmall>
                </Stack>
              </Stack>
            ) : (
              <FilePreview fileName={resumeField.value.name} onDelete={(): void => resumeField.onChange(undefined)} />
            )}
          </Stack>
          <Stack spacing={0.5}>
            <BodySmall weight="600">First Name</BodySmall>
            {isParsingResume ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <TextField
                error={!!errors.firstName}
                errorMessage="First name is required"
                placeholderText="Enter first name"
                onTextUpdated={firstName.onChange}
                text={firstName.value}
              />
            )}
          </Stack>
          <Stack spacing={0.5}>
            <BodySmall weight="600">Last Name</BodySmall>
            {isParsingResume ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <TextField
                error={!!errors.lastName}
                errorMessage="Last name is required"
                placeholderText="Enter last name"
                onTextUpdated={lastName.onChange}
                text={lastName.value}
              />
            )}
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
              <BodySmall weight="600">Email</BodySmall>
              <BodySmall>(optional)</BodySmall>
            </Stack>
            {isParsingResume ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <TextField
                error={!!errors.email}
                errorMessage={errors.email?.message}
                placeholderText="Enter email"
                onTextUpdated={email.onChange}
                text={email.value}
              />
            )}
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
              <BodySmall weight="600">LinkedIn</BodySmall>
              <BodySmall>(optional)</BodySmall>
            </Stack>
            {isParsingResume ? (
              <Skeleton variant="text" width="100%" height={40} />
            ) : (
              <TextField
                error={!!errors.linkedinUrl}
                errorMessage={errors.linkedinUrl?.message}
                placeholderText="Enter URL"
                onTextUpdated={linkedinUrl.onChange}
                text={linkedinUrl.value}
              />
            )}
          </Stack>
          {existingCandidateId && (
            <Banner variant={BannerVariant.Critical}>
              <BodySmall>
                {"A candidate with this LinkedIn or email already exists for this job. "}
                <InternalLink
                  $variant="primary"
                  to={APP_ROUTE_PATHS.candidates.candidateDetail(existingCandidateId)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View
                </InternalLink>
              </BodySmall>
            </Banner>
          )}
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" alignItems={"center"} spacing={0.5}>
              <BodySmall weight="600">Source</BodySmall>
              <InfoTip text="Where did you find this person? This is used for reporting and filtering." />
            </Stack>
            <Stack direction="row" spacing={1}>
              {CANDIDATE_SOURCE_CHOICES.map(option => (
                <ButtonRadio
                  active={source.value === option.value}
                  label={option.label}
                  onClick={(): void => source.onChange(option.value)}
                  width="auto"
                />
              ))}
            </Stack>
          </Stack>
          {(source.value === "AGENCY" || source.value === "CANDIDATE_REFERRED") && (
            <>
              <Stack spacing={0.5}>
                <BodySmall weight="600">{source.value === "AGENCY" ? "Agency" : "Referrer"} Name</BodySmall>
                <TextField
                  error={!!errors.agencyName}
                  errorMessage={errors.agencyName?.message}
                  placeholderText="Enter name"
                  onTextUpdated={agencyName.onChange}
                  text={agencyName.value}
                />
              </Stack>
              <Stack spacing={0.5}>
                <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
                  <BodySmall weight="600">{source.value === "AGENCY" ? "Agency" : "Referrer"} Email</BodySmall>
                  {source.value === "CANDIDATE_REFERRED" && <BodySmall>(optional)</BodySmall>}
                </Stack>
                <TextField
                  error={!!errors.agencyEmail}
                  errorMessage={errors.agencyEmail?.message}
                  placeholderText="Enter email"
                  onTextUpdated={agencyEmail.onChange}
                  text={agencyEmail.value}
                />
              </Stack>
            </>
          )}
          {jobsLoading ? (
            <></>
          ) : (
            <>
              <Stack spacing={0.5}>
                <BodySmall weight="600">Job</BodySmall>
                <AddCandidateJobSelector
                  jobId={jobId}
                  setJobId={(jobId): void => {
                    setJobId(jobId);
                    setHpsId(undefined);
                  }}
                />
              </Stack>
              <Stack spacing={0.5}>
                <BodySmall weight="600">Stage</BodySmall>
                <PipelineStageSelect
                  job={job}
                  initialHpsId={hpsId}
                  setPipelineStage={(stage): void => setHpsId(stage?.id)}
                />
              </Stack>
            </>
          )}
          {appliedHpsId && appliedHpsId === hpsId && (
            <Banner variant={BannerVariant.Warning}>
              <BodySmall>
                Candidates manually added to the Applied stage will not be visible in Dover&apos;s Application Review
                interface. To view applicants via this interface, please apply candidates via the job&apos;s
                <Box
                  sx={{ cursor: "pointer", color: colors.link, display: "inline", paddingLeft: "4px" }}
                  onClick={(): void => {
                    window.open(`${appConfig.appUrl}/apply/${job?.client}/${job?.id}`, "_blank", "noopener noreferrer");
                  }}
                >
                  application page
                </Box>
                .
              </BodySmall>
            </Banner>
          )}

          <Stack direction="row" justifyContent="flex-end" sx={{ p: 0 }}>
            <Button
              disabled={formHasErrors || missingJobOrStageID || !!existingCandidateId}
              tooltip={
                existingCandidateId
                  ? "Candidate already exists"
                  : missingJobOrStageID
                  ? "Select a job and stage to add candidate"
                  : undefined
              }
              onClick={handleSubmit(addCandidate)}
              variant={ButtonVariant.Primary}
            >
              Add Candidate
            </Button>
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};

export const addCandidateV2DrawerAtom = modalAtom(AddCandidateV2Drawer);
