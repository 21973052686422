import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom, useAtomValue } from "jotai";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import SourcingAutopilotPng from "assets/images/sourcing-linkedin.png";
import {
  CalibrationSettingLabels,
  EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE,
} from "components/dover/dover-outbound-modal/constants";
import DoverOutboundModal from "components/dover/dover-outbound-modal/DoverOutboundModal";
import { useGetCurrentCalibrationSetting } from "components/dover/dover-outbound-modal/hooks";
import { getDoverOutboundConfigOptionFromEmailsPerWeek } from "components/dover/dover-outbound-modal/utils";
import {
  JobFeatureSettingToggleHandler,
  JobFeatureSettingToggleHandlerContext,
} from "components/dover/feature-toggle-handler/types";
import DisabledAutopilotActivationTooltip from "components/dover/SearchesTable/components/DisabledAutopilotActivationTooltip";
import { SnoozeJobModal } from "components/dover/SnoozeJobModal";
import { Label } from "components/dover/SnoozeJobModal/styles";
import { ToggleEnablingAutopilotAtom } from "components/dover/sourcing/atoms";
import { SourcingAutopilotModal } from "components/dover/SourcingAutopilotModal/SourcingAutopilotModal";
import { Button, ButtonVariant } from "components/library/Button";
import { SELECTED_TAB_QUERY_PARAM_KEY } from "components/library/TabBar/constants";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useShouldDisableAutopilotSetup from "services/doverapi/cross-endpoint-hooks/useDisableAutopilotSetup";
import useEnableAutopilotV2 from "services/doverapi/cross-endpoint-hooks/useEnableAutopilotV2";
import { useGetDoverOutboundCandidateSource } from "services/doverapi/cross-endpoint-hooks/useGetDoverOutboundCandidateSource";
import { useGetJobFeatureUIState } from "services/doverapi/cross-endpoint-hooks/useGetJobFeatureUIState";
import { useGetDoverPlan, useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useUnsnoozeJobMutation } from "services/doverapi/endpoints/job";
import { useGetJobQuery, useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetJobIsSnoozed } from "services/doverapi/endpoints/job/hooks";
import { JobFeatureUIState } from "services/doverapi/endpoints/job/types";
import {
  useGetJobFeaturesQuery,
  useUpsertJobFeatureSettingMutation,
} from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import { useGetDoverOutboundConfigurationQuery } from "services/doverapi/endpoints/jobFulfillment";
import {
  ClientDoverPlanEnum,
  GetDoverOutboundConfiguration200ResponseShouldEngagePassiveEnum,
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { getLocalDateString } from "utils/dates";
import { editConfigModalOpenAtom } from "views/job/JobSetup/atoms";
import { CardBadge } from "views/job/JobSetup/steps/Overview/components/CardBadge";
import { TemplateCard } from "views/job/JobSetup/steps/Overview/components/DoverSolutionsTemplateCard";
import { DropdownButtonMenu, EditDropdownMenuItem } from "views/job/JobSetup/steps/Overview/components/DropdownButton";
import { UpdateSnoozeModal } from "views/job/JobSetup/steps/Overview/components/UpdateSnoozeModal";
import { ModalKeyType } from "views/job/types";

const SourcingAutopilotImg = (): React.ReactElement => {
  return <img src={SourcingAutopilotPng} style={{ width: "100%" }} />;
};

const SourcingAutopilotDescription = (): React.ReactElement => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);
  return (
    <BodySmall color={colors.grayscale.gray500}>
      {useLegacySourcingAutopilot
        ? `Receive candidates that match your exact criteria. Dover finds and emails candidates for you, making outbound
      recruiting completely effortless.`
        : `Work with your Recruiting Partner to define exact search criteria. Then Dover finds and emails candidates for you, making outbound recruiting completely effortless.`}
    </BodySmall>
  );
};

const SOURCING_AUTOPILOT_TITLE = "Sourcing Autopilot";
const SOURCING_TITLE = "Delegate Sourcing";

interface EditSourcingAutopilotProps {
  isDoverOutboundEnabled: boolean;
  jobIsSnoozed: boolean;
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
}

const EditSourcingAutopilot = ({
  isDoverOutboundEnabled,
  jobIsSnoozed,
  modalOpen,
  setModalOpen,
}: EditSourcingAutopilotProps): React.ReactElement => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);

  const { jobId } = useParams<{ jobId: string }>();
  const [, { isLoading: jobFeatureSettingIsUpdating }] = useUpsertJobFeatureSettingMutation();
  const [, setTabParam] = useQueryParam(SELECTED_TAB_QUERY_PARAM_KEY, StringParam);

  const {
    emailsPerWeek,
    doverOutboundConfigOption,
    passiveCandidates,

    isFetchingConfigOption,
    autoPilotEnabled: autoSendEnabled,
    similarCandidatesSourcingTarget,
  } = useGetDoverOutboundConfigurationQuery(jobId ?? skipToken, {
    selectFromResult: ({ data, isFetching }) => {
      return {
        emailsPerWeek: data?.emailsPerWeek,
        doverOutboundConfigOption: getDoverOutboundConfigOptionFromEmailsPerWeek(data?.emailsPerWeekOption),
        passiveCandidates:
          data?.shouldEngagePassive ===
          GetDoverOutboundConfiguration200ResponseShouldEngagePassiveEnum.InterestedInRole,
        isFetchingConfigOption: isFetching,
        phoneScreensPerWeek: data?.phoneScreensPerWeek,
        autoPilotEnabled: !!data?.autoQueueOutreach,
        similarCandidatesSourcingTarget: data?.similarCandidatesSourcingTarget,
      };
    },
  });

  const { data: job } = useGetJobSetupQuery(jobId ? jobId : skipToken);

  const onEditSourcingConfig = React.useCallback(
    (e: React.SyntheticEvent) => {
      setTabParam(EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE);
      setModalOpen(true);
      e.stopPropagation();
    },
    [setTabParam, setModalOpen]
  );
  const doverOutboundCandidateSource = useGetDoverOutboundCandidateSource(jobId);
  const statsContainerCalibrationSetting = useGetCurrentCalibrationSetting(job);

  const getAutoSendStatus = (): React.ReactElement => {
    if (jobFeatureSettingIsUpdating) {
      return <DoverLoadingSpinner height="12px" width="12px" spinnerSize="12px" />;
    }
    let statusText = "";

    if (!isDoverOutboundEnabled || !autoSendEnabled) {
      statusText = "Off";
    } else if (jobIsSnoozed) {
      statusText = "Paused";
    } else {
      statusText = "On";
    }

    return <BodySmall>{statusText}</BodySmall>;
  };

  const getSimilarCandidatesStatus = (): React.ReactElement => {
    if (jobFeatureSettingIsUpdating) {
      return <DoverLoadingSpinner height="12px" width="12px" spinnerSize="12px" />;
    }
    const statusText = similarCandidatesSourcingTarget ? "On" : "Off";

    return <BodySmall>{statusText}</BodySmall>;
  };

  return (
    <>
      <SourcingStatsContainer direction="row" justifyContent="space-between" onClick={onEditSourcingConfig}>
        <Stack direction="row" spacing={3}>
          <SettingsIcon />
          <Stack spacing={0.5}>
            {isFetchingConfigOption || !doverOutboundConfigOption ? (
              <DoverLoadingSpinner minHeight="18px" height="18px" width="64px" spinnerSize="15px" />
            ) : (
              <Stack spacing={0.5} justifyContent="space-between" height="100%">
                <BodySmall color={colors.grayscale.gray500}>Email volume</BodySmall>
                <BodySmall>{`${emailsPerWeek}/wk`}</BodySmall>
              </Stack>
            )}
          </Stack>
          {useLegacySourcingAutopilot && (
            <Stack spacing={0.5} justifyContent="space-between" height="100%">
              <BodySmall color={colors.grayscale.gray500}>Calibration Settings</BodySmall>
              <BodySmall>{CalibrationSettingLabels[statsContainerCalibrationSetting]}</BodySmall>
            </Stack>
          )}
          <Stack spacing={0.5}>
            {isFetchingConfigOption ? (
              <DoverLoadingSpinner minHeight="18px" height="18px" width="64px" spinnerSize="15px" />
            ) : (
              <Stack spacing={0.5} justifyContent="space-between" height="100%">
                <BodySmall color={colors.grayscale.gray500}>Passive candidates</BodySmall>
                <BodySmall>{passiveCandidates ? "On" : "Off"}</BodySmall>
              </Stack>
            )}
          </Stack>
          <Stack spacing={0.5}>
            {isFetchingConfigOption ? (
              <DoverLoadingSpinner minHeight="18px" height="18px" width="64px" spinnerSize="15px" />
            ) : (
              <Stack spacing={0.5} justifyContent="space-between" height="100%">
                <BodySmall color={colors.grayscale.gray500}>Autosend</BodySmall>
                {getAutoSendStatus()}
              </Stack>
            )}
          </Stack>
          <Stack spacing={0.5}>
            {isFetchingConfigOption ? (
              <DoverLoadingSpinner minHeight="18px" height="18px" width="64px" spinnerSize="15px" />
            ) : (
              <Stack spacing={0.5} justifyContent="space-between" height="100%">
                <BodySmall color={colors.grayscale.gray500}>Similar Candidates</BodySmall>
                {getSimilarCandidatesStatus()}
              </Stack>
            )}
          </Stack>
        </Stack>
      </SourcingStatsContainer>
      <DoverOutboundModal
        isModalOpen={modalOpen}
        candidateSource={doverOutboundCandidateSource}
        toggleModalOff={(): void => setModalOpen(false)}
        isDoverOutboundEnabled={isDoverOutboundEnabled}
        isUpdating={false}
      />
    </>
  );
};

const SourcingStatsContainer = styled(Stack)`
  padding: 12px 16px;
  border: 1px solid ${colors.grayscale.gray200};
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: ${colors.grayscale.gray100};
  }
`;

export const SourcingAutopilotCard = (): React.ReactElement => {
  // params
  const { jobId } = useParams<{ jobId: string }>();

  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);

  // queries
  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);
  const isBasePlanCustomer = useIsBasePlanCustomer();
  const doverPlan = useGetDoverPlan();

  // custom hooks
  const jobIsSnoozed = useGetJobIsSnoozed(jobId!);
  const shouldEnableAutopilotJobFeature = doverPlan !== ClientDoverPlanEnum.Free;
  const enableAutopilotV2 = useEnableAutopilotV2(job, shouldEnableAutopilotJobFeature);

  const theme = useTheme();
  const largerThanLaptop = !useMediaQuery(theme.breakpoints.down(screenSizesNumbers.laptopML));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.laptop));

  const disableAutopilotSetup = useShouldDisableAutopilotSetup(jobId);
  const autopilotSetupDisabled = disableAutopilotSetup();

  // mutations
  const [unsnoozeJob] = useUnsnoozeJobMutation();
  // cache key needed for tracking loading state of enabling autopilot
  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  // Loading state for enabling autopilot hook
  const isEnablingAutopilot = useAtomValue(ToggleEnablingAutopilotAtom);

  // local state
  const [snoozeModalOpen, setSnoozeModalOpen] = useState<ModalKeyType>(ModalKeyType.None);
  const [snoozeReason, setSnoozeReason] = useState(jobIsSnoozed && job?.snoozedReason ? job?.snoozedReason : "");
  const snoozedUntil = job?.snoozedUntil ?? null;
  const [selectedDate, setSelectedDate] = useState<Date | null>(jobIsSnoozed ? snoozedUntil : null);
  const [, setConfirmationHeaderText] = useState<React.ReactElement>(<></>);
  const [sourcingAutopilotModalOpen, setSourcingAutopilotModalOpen] = React.useState<boolean>(false);
  const [editConfigModalOpen, setEditConfigModalOpen] = useAtom(editConfigModalOpenAtom);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  // derived state
  const jobFeatureUIState = useGetJobFeatureUIState({
    featureName: JobFeatureFeatureNameEnum.ManagedOutbound,
    jobId,
    allowAutoEnablement: true,
  });

  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
      };
    },
  });

  const isDoverOutboundEnabled = !!jobFeatures?.[JobFeatureFeatureNameEnum.ManagedOutbound];
  const autoPilotOn = isDoverOutboundEnabled && jobFeatureUIState === JobFeatureUIState.Active;
  const autoPilotPaused = isDoverOutboundEnabled && jobIsSnoozed;

  // handlers
  const handleUnsnoozeJob = React.useCallback(async (): Promise<void> => {
    await unsnoozeJob({ jobId: jobId! }).unwrap();
  }, [jobId, unsnoozeJob]);

  const handlePause = (): void => {
    // unsnooze job
    if (jobIsSnoozed) {
      handleUnsnoozeJob();
    }
    // open snooze job modal
    else {
      setSnoozeModalOpen(ModalKeyType.Snooze);
    }
  };

  const handleEditButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const jobFeatureToggleHandler = React.useContext<JobFeatureSettingToggleHandler | undefined>(
    JobFeatureSettingToggleHandlerContext
  );

  // callbacks
  const deactivateTargetedSourcing = React.useCallback(() => {
    jobFeatureToggleHandler?.onFeatureToggled({
      featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
      desiredFeatureState: UpsertJobFeatureSettingStateEnum.Disabled,
    });
  }, [jobFeatureToggleHandler]);

  const toggleManagedOutbound = React.useCallback(
    (enabled: boolean) => {
      upsertJobFeatureSetting({
        upsertData: {
          data: {
            job: jobId!,
            featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
            state: enabled ? UpsertJobFeatureSettingStateEnum.Enabled : UpsertJobFeatureSettingStateEnum.Disabled,
          },
        },
      });
    },
    [jobId, upsertJobFeatureSetting]
  );

  const openCompletionModal = React.useCallback(() => {
    if (snoozeModalOpen === ModalKeyType.Snooze) {
      setConfirmationHeaderText(<Label>{`The job search is being paused`}</Label>);
    } else if (snoozeModalOpen === ModalKeyType.UpdateSnooze) {
      setConfirmationHeaderText(<Label>{`The job search is being updated and will still be paused`}</Label>);
    } else {
      setConfirmationHeaderText(<Label>{`The job search is being unpaused`}</Label>);
    }
    setSnoozeModalOpen(ModalKeyType.Completion);
  }, [snoozeModalOpen]);

  const getFeatureStateBadge = React.useCallback(() => {
    if (!jobFeatureUIState) {
      return <></>;
    }

    let overrideJobFeatureUIState = jobFeatureUIState;
    let jobSnoozedUntil = undefined;

    if (autoPilotPaused) {
      overrideJobFeatureUIState = JobFeatureUIState.Paused;
      jobSnoozedUntil = snoozedUntil ? getLocalDateString(snoozedUntil) : undefined;
    }
    // special case where sourcing autopilot needs setup but dover outbound is not enabled
    // should just show that it's inactive
    else if (!isDoverOutboundEnabled && jobFeatureUIState === JobFeatureUIState.NeedsSetup) {
      overrideJobFeatureUIState = JobFeatureUIState.Inactive;
    }

    return <CardBadge jobFeatureUIState={overrideJobFeatureUIState} jobSnoozedUntil={jobSnoozedUntil} />;
  }, [jobFeatureUIState, isDoverOutboundEnabled, autoPilotPaused, snoozedUntil]);

  // memoized values
  const Actions = React.useMemo(() => {
    if (isEnablingAutopilot) {
      return <DoverLoadingSpinner minHeight="40px" height="40px" spinnerSize="30px" width="40px" />;
    }

    const autopilotDisabledTooltip = job?.isSample ? (
      "This feature is not available for sample jobs"
    ) : autopilotSetupDisabled && jobId ? (
      <DisabledAutopilotActivationTooltip jobId={jobId} />
    ) : (
      undefined
    );

    const GetStartedButton = (
      <Tooltip title={autopilotDisabledTooltip}>
        <div>
          <Button
            variant={ButtonVariant.SecondarySuccess}
            onClick={enableAutopilotV2}
            disabled={job?.isSample || autopilotSetupDisabled}
            width="100%"
          >
            {isBasePlanCustomer ? "Get started" : "Enable"}
          </Button>
        </div>
      </Tooltip>
    );

    if (autoPilotOn) {
      const deactivateDropdown = (
        <EditDropdownMenuItem
          onClick={(): void => {
            deactivateTargetedSourcing();
            handleClose();
          }}
        >
          <BodySmall color={colors.critical.base}>Deactivate</BodySmall>
        </EditDropdownMenuItem>
      );
      const pauseDropdown = (
        <EditDropdownMenuItem
          onClick={(): void => {
            setSnoozeModalOpen(ModalKeyType.Snooze);
            handleClose();
          }}
        >
          <BodySmall>Pause</BodySmall>
        </EditDropdownMenuItem>
      );
      const unpauseDropdown = (
        <EditDropdownMenuItem
          onClick={(): void => {
            handleUnsnoozeJob();
            handleClose();
          }}
        >
          <BodySmall>Unpause</BodySmall>
        </EditDropdownMenuItem>
      );
      const updatePauseDropdown = (
        <EditDropdownMenuItem
          onClick={(): void => {
            setSnoozeModalOpen(ModalKeyType.UpdateSnooze);
            handleClose();
          }}
        >
          <BodySmall>Edit Pause</BodySmall>
        </EditDropdownMenuItem>
      );
      // if the job is paused, give option to unpause or modify the pause
      const options = !autoPilotPaused
        ? [pauseDropdown, deactivateDropdown]
        : [unpauseDropdown, updatePauseDropdown, deactivateDropdown];

      return (
        <>
          <Button onClick={handleEditButtonClick} variant={ButtonVariant.Secondary} removeOutline width="100%">
            {isSmallScreen ? (
              <KeyboardArrowDownIcon />
            ) : (
              <Stack direction="row" alignItems="center">
                Configure
                <KeyboardArrowDownIcon />
              </Stack>
            )}
          </Button>
          <DropdownButtonMenu anchorEl={anchorEl} handleClose={handleClose} options={options} />
        </>
      );
    } else if (isDoverOutboundEnabled && jobFeatureUIState === JobFeatureUIState.NeedsSetup) {
      return (
        <Stack spacing={1}>
          <Tooltip title={autopilotDisabledTooltip}>
            <div>
              <Button
                variant={ButtonVariant.SecondarySuccess}
                onClick={enableAutopilotV2}
                width="100%"
                disabled={autopilotSetupDisabled}
              >
                Complete setup
              </Button>
            </div>
          </Tooltip>
          <Button variant={ButtonVariant.SecondaryCritical} onClick={(): void => toggleManagedOutbound(false)}>
            Cancel
          </Button>
        </Stack>
      );
    }
    return GetStartedButton;
  }, [
    anchorEl,
    autoPilotOn,
    autoPilotPaused,
    autopilotSetupDisabled,
    deactivateTargetedSourcing,
    enableAutopilotV2,
    handleUnsnoozeJob,
    isBasePlanCustomer,
    isDoverOutboundEnabled,
    isEnablingAutopilot,
    isSmallScreen,
    jobFeatureUIState,
    jobId,
    toggleManagedOutbound,
    job?.isSample,
  ]);

  const InnerBody = autoPilotOn ? <></> : <SourcingAutopilotDescription />;

  const settings = autoPilotOn ? (
    <EditSourcingAutopilot
      modalOpen={editConfigModalOpen}
      setModalOpen={setEditConfigModalOpen}
      isDoverOutboundEnabled={isDoverOutboundEnabled}
      jobIsSnoozed={jobIsSnoozed}
    />
  ) : (
    undefined
  );

  const shouldShowImage = React.useMemo(() => {
    return !autoPilotOn && largerThanLaptop && isBasePlanCustomer;
  }, [autoPilotOn, isBasePlanCustomer, largerThanLaptop]);

  if (!jobId) {
    return <></>;
  }

  const title = useLegacySourcingAutopilot ? SOURCING_AUTOPILOT_TITLE : SOURCING_TITLE;

  return (
    <>
      <SnoozeJobModal
        shouldShowModal={snoozeModalOpen}
        handleCloseModal={(): void => setSnoozeModalOpen(ModalKeyType.None)}
        handleOpenCompletionModal={openCompletionModal}
        modalKey={ModalKeyType.Snooze}
        jobName={job?.name}
        jobId={jobId}
        snoozeReason={snoozeReason}
        setSnoozeReason={setSnoozeReason}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <UpdateSnoozeModal
        shouldShowModal={snoozeModalOpen}
        handleCloseModal={(): void => setSnoozeModalOpen(ModalKeyType.None)}
        handleOpenCompletionModal={openCompletionModal}
        modalKey={ModalKeyType.UpdateSnooze}
        jobName={job?.title || undefined}
        jobId={jobId}
        snoozeReason={snoozeReason}
        setSnoozeReason={setSnoozeReason}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <SourcingAutopilotModal
        jobId={jobId}
        isModalOpen={sourcingAutopilotModalOpen}
        onModalClose={(): void => {
          setSourcingAutopilotModalOpen(false);
        }}
        showPauseButton={autoPilotOn}
        onPause={handlePause}
      />
      <TemplateCard
        imageComponent={shouldShowImage ? <SourcingAutopilotImg /> : undefined}
        title={title}
        body={InnerBody}
        actionButton={Actions}
        learnMoreLink={APP_ROUTE_PATHS.searches()}
        featureStateBadge={getFeatureStateBadge()}
        featureActiveAndSetup={autoPilotOn}
        feature={JobFeatureFeatureNameEnum.ManagedOutbound}
        settings={settings}
      />
    </>
  );
};

export const SourcingAutopilotCardPreview = (): React.ReactElement => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);
  const title = useLegacySourcingAutopilot ? SOURCING_AUTOPILOT_TITLE : SOURCING_TITLE;

  return (
    <TemplateCard imageComponent={<SourcingAutopilotImg />} title={title} body={<SourcingAutopilotDescription />} />
  );
};
