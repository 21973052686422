import { Button } from "@doverhq/dover-ui";
import { ReactComponent as FilterIcon } from "@doverhq/dover-ui/icons/filter.svg";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { PressEvent } from "@react-types/shared/src/";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useMemo, useState } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as GreenCheckSVG } from "assets/icons/check-green-filled.svg";
import { ReactComponent as ArrowRight } from "assets/icons/chevron-right.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListApplicationQuestionsQuery } from "services/doverapi/endpoints/applicationQuestion";
import { useGetFormattedPreviouslyAddedInboundSourceRows } from "services/doverapi/endpoints/job-source-settings/customHooks";
import {
  useGetCandidateSourceStatsQuery,
  useGetJobCandidateSourceSettingsQuery,
  useGetJobCandidateSourcesQuery,
} from "services/doverapi/endpoints/job-source-settings/endpoints";
import { ApplicationQuestionQuestionTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import {
  hasFiltersAppliedAtom,
  inboundSourceFilterAtom,
  toggleSourceFilterAtom,
} from "views/candidates/ApplicationReview/atoms/filters";
import { ApplicationQuestionsSection } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicationQuestions";

const InnerMenuTextDisplay = ({ text }: { text: string }): React.ReactElement => {
  return (
    <Box p={3}>
      <BodySmall>{text}</BodySmall>
    </Box>
  );
};

const ApplicantFilters = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  // State management
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [sourceFiltersAnchorEl, setSourceFiltersAnchorEl] = useState<HTMLElement | null>(null);
  const [applicationQuestionsAnchorEl, setApplicationQuestionsAnchorEl] = useState<HTMLElement | null>(null);

  const inboundSourceFilter = useAtomValue(inboundSourceFilterAtom);

  const toggleSourceFilter = useSetAtom(toggleSourceFilterAtom);

  // RTK Query/Mutation
  const { data: applicationQuestions, isLoading: isAppQuestionsLoading } = useListApplicationQuestionsQuery(
    jobId ? { job: jobId } : skipToken
  );
  const customAppQuestions = applicationQuestions?.filter(
    question => question.questionType === ApplicationQuestionQuestionTypeEnum.Custom
  );

  const { isFetching: areCandidateSourcesFetching } = useGetJobCandidateSourcesQuery(
    jobId
      ? {
          jobId,
          includeAdminPreview: false,
          includeYcWaas: true,
        }
      : skipToken
  );
  const { isFetching: isFetchingJobCandidateSourceSettings } = useGetJobCandidateSourceSettingsQuery(
    jobId ? { jobId, includeAdminPreview: false, includeYcWaas: true } : skipToken
  );
  const { isFetching: areCandidateSourceStatsFetching } = useGetCandidateSourceStatsQuery(
    jobId
      ? {
          jobId,
        }
      : skipToken
  );

  const formattedActiveInboundSourceRows = useGetFormattedPreviouslyAddedInboundSourceRows({
    jobId,
    includeAdminPreview: false,
    includeYcWaas: true,
  });

  // Components
  const inboundSourceFilterOptions = useMemo((): string[] => {
    if (!formattedActiveInboundSourceRows) {
      return [];
    }
    // every job should have a dover careers page option
    return ["Dover Careers Page", ...formattedActiveInboundSourceRows.map(row => row.name ?? "")];
  }, [formattedActiveInboundSourceRows]);

  const formattedActiveInboundSourceRowsFetching =
    areCandidateSourcesFetching || isFetchingJobCandidateSourceSettings || areCandidateSourceStatsFetching;

  const openMenu = React.useCallback((event: PressEvent): void => {
    setAnchorEl(event.target);
  }, []);

  const closeMenu = React.useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const SourceFiltersSection = React.useMemo(() => {
    if (formattedActiveInboundSourceRowsFetching || !jobId) {
      return <InnerMenuTextDisplay text="Loading..." />;
    }
    return (
      <>
        {inboundSourceFilterOptions.map(option => {
          const optionSelected = inboundSourceFilter?.includes(option.toString());

          return (
            <MenuItem value={option} onClick={(): void => toggleSourceFilter(option)} key={option}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <BodySmall>{option}</BodySmall>
                {optionSelected && (
                  <Box paddingLeft={"8px"} display="flex" justifyContent="center">
                    <GreenCheckSVG height="16px" width="16px" />
                  </Box>
                )}
              </Stack>
            </MenuItem>
          );
        })}
        <Box px={2} py={1}>
          <BodySmall>
            <InternalLink
              to={APP_ROUTE_PATHS.job.jobPosting(jobId, new URLSearchParams({ jobPostingTab: "job-boards" }))}
            >
              + Add job boards
            </InternalLink>
          </BodySmall>
        </Box>
      </>
    );
  }, [
    formattedActiveInboundSourceRowsFetching,
    toggleSourceFilter,
    inboundSourceFilterOptions,
    inboundSourceFilter,
    jobId,
  ]);

  const MainMenuEl = React.useMemo(() => {
    return (
      <>
        <MenuItem onClick={(event: any): void => setSourceFiltersAnchorEl(event.target)}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall>Source</BodySmall>
            <ArrowRight />
          </Stack>
        </MenuItem>
        <MenuItem onClick={(event: any): void => setApplicationQuestionsAnchorEl(event.target)}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall>Application questions</BodySmall>
            <ArrowRight />
          </Stack>
        </MenuItem>
      </>
    );
  }, []);

  // if filters are applied, we will display the "Filters" button differently
  const hasFiltersApplied = useAtomValue(hasFiltersAppliedAtom);

  // Final render
  return (
    <>
      <Tooltip title="Filter">
        <div>
          <Button
            p={1}
            variant="ghost"
            icon={{ Icon: FilterIcon, color: hasFiltersApplied ? "primary-base" : undefined }}
            onPress={openMenu}
          >
            <BodySmall color={colors.grayscale.gray600}>Filters</BodySmall>
          </Button>
        </div>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        {MainMenuEl}
      </Menu>
      <Menu
        anchorEl={sourceFiltersAnchorEl}
        open={Boolean(sourceFiltersAnchorEl)}
        onClose={(): void => setSourceFiltersAnchorEl(null)}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {SourceFiltersSection}
      </Menu>
      <Menu
        anchorEl={applicationQuestionsAnchorEl}
        open={Boolean(applicationQuestionsAnchorEl)}
        onClose={(): void => setApplicationQuestionsAnchorEl(null)}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {isAppQuestionsLoading ? (
          <InnerMenuTextDisplay text="Loading..." />
        ) : customAppQuestions?.length ? (
          <ApplicationQuestionsSection customAppQuestions={customAppQuestions} />
        ) : (
          <InnerMenuTextDisplay text="No application questions exist for this job" />
        )}
      </Menu>
    </>
  );
};

export default ApplicantFilters;
