import { Stack } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ChromeSVG } from "assets/icons/chrome-gray.svg";
import { SOURCING_EXTENSION_STORE_URL } from "components/constants";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Heading } from "components/library/typography";
import RecruitingPartnerVideo from "components/RecruitingPartnerVideo";
import { SetupGuide, SetupGuideStep, seenSetupGuideExtensionAd } from "components/SetupGuide";
import { ReactComponent as CreateCareersPageIllustration } from "components/SetupGuide/illustrations/create-careers-page.svg";
import { ReactComponent as ExtensionIllustration } from "components/SetupGuide/illustrations/extension.svg";
import { ReactComponent as InterviewPreferencesIllustration } from "components/SetupGuide/illustrations/interview-preferences.svg";
import { ReactComponent as PostJobBoardsIllustration } from "components/SetupGuide/illustrations/post-job-boards.svg";
import { CTAButton, GrayBody } from "components/SetupGuide/SetupGuideCTA";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useHandleAddCandidate } from "sections/addcandidate/components/AddCandidateButton";
import { useGetCompanySetupStepsQuery } from "services/doverapi/endpoints/client/endpoints";
import { selectFromListJobsQueryResult } from "services/doverapi/endpoints/job";
import { useListJobsQuery } from "services/doverapi/endpoints/job/endpoints";
import {
  useGetAuthedUserInfoQuery,
  useGetUserInterviewerSchedulingInfoQuery,
  usePartialUpdateProUserMutation,
} from "services/doverapi/endpoints/proUser";
import { CompanySetupStepStateEnum, CompanySetupStepStepNameEnum } from "services/openapi";
import { colors } from "styles/theme";
import { FakeLink } from "styles/typography";
import { useHasManuallySourcedCandidates } from "views/candidates/hooks";

export const HomepageSetupGuide = ({ jobId }: { jobId: string }): React.ReactElement => {
  const [hasDownloadedExtension, setHasDownloadedExtension] = useAtom(seenSetupGuideExtensionAd);
  const showExtensionAd = useFeatureFlag(FeatureFlag.AdvertiseSourcingExtension);

  const handleAddCandidate = useHandleAddCandidate();
  const calendlyUrl = useGetCalendlyUrl("app", "setup_guide", "meet_our_team_video", true);

  // make requests for setup steps
  const { activeJobs, isFetching } = useListJobsQuery(undefined, {
    selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults),
  });
  const onlyHasSampleJob = activeJobs?.length === 1 && activeJobs[0].isSample;

  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const [partialUpdateProUser] = usePartialUpdateProUserMutation();

  const { data: companySetupSteps, isLoading: isCompanySetupStepsLoading } = useGetCompanySetupStepsQuery();
  const {
    data: currentInterviewerSchedulingInfo,
    isLoading: isInterviewerSchedulingInfoLoading,
  } = useGetUserInterviewerSchedulingInfoQuery();
  const { isFetching: isFetchingManuallySourcedCandidates } = useHasManuallySourcedCandidates(jobId);

  // We do not want to display the ATS setup steps if the client's ATS type
  // is one of Lever, Greenhouse, or Ashby
  // determine steps completed
  const hasCareersPage =
    companySetupSteps?.setupSteps?.find(step => step.stepName === CompanySetupStepStepNameEnum.CareersPageState)
      ?.state === CompanySetupStepStateEnum.Complete;
  const hasInterviewPreferences = !!currentInterviewerSchedulingInfo?.googleMeetEnabled;

  const [hasImportedCandidate, setHasImportedCandidate] = useLocalStorage("hasImportedCandidate", false);
  const [hasBookedConsultation, setHasBookedConsultation] = useLocalStorage("hasBookedConsultation", false);

  const dismissGuide = (): void => {
    if (userInfo?.id) {
      partialUpdateProUser({ user: userInfo.id.toString(), data: { setupGuideDismissed: true } });
    }
  };

  const isLoading =
    isCompanySetupStepsLoading ||
    isInterviewerSchedulingInfoLoading ||
    isFetchingManuallySourcedCandidates ||
    isFetching;

  const steps: Array<SetupGuideStep> = [
    {
      name: "Create your careers page",
      content: <GrayBody>Create a job to start hiring.</GrayBody>,
      graphic: <CreateCareersPageIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.editCareersPage()}>Get started</CTAButton>,
      isComplete: hasCareersPage,
    },
    {
      name: "Add interview preferences",
      isComplete: hasInterviewPreferences,
      content: (
        <GrayBody>
          Set your interview preferences and send candidates <b>personalized unique scheduling links</b> to book
          interviews.
        </GrayBody>
      ),
      graphic: <InterviewPreferencesIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.userSettings.interviewPreferences()}>Set preferences</CTAButton>,
    },
    {
      name: "View recruiting partners",
      isComplete: hasBookedConsultation,
      content: (
        <GrayBody>
          <b>Dover Recruiting Partner</b>
          <br />
          Dover drives your hiring process and helps you find and close top talent.
        </GrayBody>
      ),
      graphic: (
        <Stack justifyContent={"center"} width="100%" height="100%">
          <RecruitingPartnerVideo width={"100%"} height={"100%"} onPlay={(): void => setHasBookedConsultation(true)} />
        </Stack>
      ),
      ctaButton: (
        <CTAButton
          onPress={(): void => {
            setHasBookedConsultation(true);
            window.open(calendlyUrl, "_blank", "noopener noreferrer");
          }}
        >
          Get started for free
        </CTAButton>
      ),
    },
    ...(showExtensionAd
      ? [
          {
            name: "Get Sourcing Extension",
            isComplete: hasDownloadedExtension,
            ctaMaxWidth: "70%",
            content: (
              <GrayBody>
                <b>Reach out to candidates on LinkedIn</b>
                <br />
                Add candidates to outreach campaigns as you find them on LinkedIn
              </GrayBody>
            ),
            graphic: <ExtensionIllustration />,
            ctaButton: (
              <CTAButton
                onPress={(): void => {
                  setHasDownloadedExtension(true);
                  window.open(SOURCING_EXTENSION_STORE_URL, "_blank", "noopener noreferrer");
                }}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <ChromeSVG className="svg-color" color={colors.white} />
                  <span>Download Chrome Extension</span>
                </Stack>
              </CTAButton>
            ),
          },
        ]
      : []),
    {
      name: "Create a job",
      isComplete: !isFetching && !onlyHasSampleJob,
      content: <GrayBody>Create a job to start hiring.</GrayBody>,
      graphic: <PostJobBoardsIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.createJob()}>Get started</CTAButton>,
    },
    {
      name: "Import candidates",
      isComplete: hasImportedCandidate,
      content: (
        <Stack spacing={1}>
          <Stack direction="row" spacing={0.5}>
            <GrayBody>Fewer than 10 candidates?</GrayBody>
            <FakeLink
              onClick={(): void => {
                setHasImportedCandidate(true);
                handleAddCandidate();
              }}
            >
              Click here
            </FakeLink>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <GrayBody>More than 10 candidates?</GrayBody>
            <FakeLink
              onClick={(): void => {
                setHasImportedCandidate(true);
                window.open("https://airtable.com/appATGSUatX5ywtVN/pagn9YFFBego5nXPH/form", "_blank");
              }}
            >
              Request a free migration
            </FakeLink>
          </Stack>
        </Stack>
      ),
    },
  ];

  return (
    <SetupGuide heading={<Heading>Get started</Heading>} steps={steps} loading={isLoading} onDismiss={dismissGuide} />
  );
};
