import React from "react";

import { SOURCING_EXTENSION_STORE_URL } from "components/constants";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Overline } from "components/library/typography";
import { colors } from "styles/theme";
import { TemplateCard } from "views/job/JobSetup/steps/Overview/components/DoverSolutionsTemplateCard";
import { BaseBadge } from "views/job/JobSetup/steps/Overview/styles";

const SourcingAutopilotDescription = (): React.ReactElement => {
  return (
    <BodySmall color={colors.grayscale.gray500}>
      Source candidates directly on LinkedIn using our chrome sourcing extension.
    </BodySmall>
  );
};

export const SourcingExtensionCard = (): React.ReactElement => {
  // memoized values
  const GetStartedButton = (
    <Button
      variant={ButtonVariant.SecondarySuccess}
      onClick={(): void => {
        window.open(SOURCING_EXTENSION_STORE_URL);
      }}
      width="100%"
    >
      Download
    </Button>
  );

  const InnerBody = <SourcingAutopilotDescription />;

  return (
    <TemplateCard
      title={"Sourcing Extension"}
      body={InnerBody}
      actionButton={GetStartedButton}
      featureStateBadge={
        <BaseBadge>
          <Overline color={colors.success.base}>NEW!</Overline>
        </BaseBadge>
      }
    />
  );
};
