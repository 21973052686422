import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

import { useJobId } from "hooks/useJobIdFromUrl";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useGetUserGmailAuthValidQuery } from "services/doverapi/endpoints/proUser";
import {
  EmailSenderAliasGmailCredentialAuthStateEnum,
  JobFeatureStateEnum,
  ListCampaign,
  ListCampaignEmailSenderOptionEnum,
  SlimEmailSenderAliasGmailCredentialAuthStateEnum,
} from "services/openapi";
import {
  useIsEligibleForCampaignOutreachGeneration,
  usePitchStepIsIrrelevantOrCompleted,
} from "views/job/JobSetup/hooks";

export function useShouldAttemptCampaignOutreachGeneration(
  jobId: string | undefined,
  dismissedJobPitchIncompleteModal: boolean,
  allowIncompleteJobPitch: boolean = false
): boolean {
  const everythingSetupAndEligible = useIsEligibleForCampaignOutreachGeneration(jobId, allowIncompleteJobPitch);
  const pitchStepIsIrrelevantOrComplete = usePitchStepIsIrrelevantOrCompleted(jobId);

  const isEligibleForCampaignOutreachGenerationButIncompleteJobPitch = useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(
    jobId
  );
  return useMemo<boolean>(() => {
    if (everythingSetupAndEligible && (allowIncompleteJobPitch || pitchStepIsIrrelevantOrComplete)) {
      return true;
    }
    return isEligibleForCampaignOutreachGenerationButIncompleteJobPitch && dismissedJobPitchIncompleteModal;
  }, [
    everythingSetupAndEligible,
    pitchStepIsIrrelevantOrComplete,
    isEligibleForCampaignOutreachGenerationButIncompleteJobPitch,
    dismissedJobPitchIncompleteModal,
    allowIncompleteJobPitch,
  ]);
}

function useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(jobId?: string): boolean {
  const pitchStepIsIrrelevantOrComplete = usePitchStepIsIrrelevantOrCompleted(jobId);
  const wouldOtherwiseAttemptCampaignOutreachGeneration = useIsEligibleForCampaignOutreachGeneration(jobId, true);

  return useMemo<boolean>(() => {
    return wouldOtherwiseAttemptCampaignOutreachGeneration && !pitchStepIsIrrelevantOrComplete;
  }, [wouldOtherwiseAttemptCampaignOutreachGeneration, pitchStepIsIrrelevantOrComplete]);
}

function useHasNotAutogeneratedCampaignContentBefore(campaigns: ListCampaign[] | undefined): boolean {
  return useMemo<boolean>(() => {
    return (
      !!campaigns &&
      campaigns.length === 1 &&
      // This checks that the only campaign has no campaign message content generated yet.
      (campaigns[0].threadMessages ?? []).every(message => !message.bodyTemplate)
    );
  }, [campaigns]);
}

export function useShouldShowIncompleteJobPitchModal(
  jobId: string | undefined,
  dismissedJobPitchIncompleteModal: boolean,
  campaigns: ListCampaign[] | undefined,
  isGeneratingCampaignContent: boolean
): boolean {
  const isEligibleForCampaignOutreachGenerationButIncompleteJobPitch = useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(
    jobId
  );
  const hasNotAutogeneratedCampaignContentBefore = useHasNotAutogeneratedCampaignContentBefore(campaigns);
  return useMemo<boolean>(() => {
    if (dismissedJobPitchIncompleteModal || isGeneratingCampaignContent) {
      return false;
    }
    return isEligibleForCampaignOutreachGenerationButIncompleteJobPitch && hasNotAutogeneratedCampaignContentBefore;
  }, [
    isEligibleForCampaignOutreachGenerationButIncompleteJobPitch,
    dismissedJobPitchIncompleteModal,
    hasNotAutogeneratedCampaignContentBefore,
    isGeneratingCampaignContent,
  ]);
}

const useIsUsingShellAccount = (): boolean | undefined => {
  const [jobId] = useJobId();

  const isFreePlan = useIsFreeCustomer();
  const managedOutboundState = useGetManagedOutboundState({ jobId });

  if (!jobId) {
    return undefined;
  }

  return !isFreePlan && managedOutboundState === JobFeatureStateEnum.Enabled;
};

interface UseIsCampaignEmailSenderAuthedProps {
  userDefinedSenderUser: number | undefined;
  emailSenderOption: ListCampaignEmailSenderOptionEnum | undefined;
  emailAliasEmail: string | undefined;
  emailAliasGmailCredentialAuthState:
    | EmailSenderAliasGmailCredentialAuthStateEnum
    | SlimEmailSenderAliasGmailCredentialAuthStateEnum
    | undefined;
}

export const useIsCampaignEmailSenderAuthed = ({
  userDefinedSenderUser,
  emailSenderOption,
  emailAliasEmail,
  emailAliasGmailCredentialAuthState,
}: UseIsCampaignEmailSenderAuthedProps): {
  isAuthed: boolean;
  isLoading: boolean;
} => {
  const isUsingShellAccount = useIsUsingShellAccount();

  const skip =
    !userDefinedSenderUser ||
    emailSenderOption === ListCampaignEmailSenderOptionEnum.VirtualRecruiter ||
    isUsingShellAccount;

  const { data: gmailAuthStatus, isLoading: isGmailAuthLoading } = useGetUserGmailAuthValidQuery(
    skip ? skipToken : { userId: userDefinedSenderUser ? userDefinedSenderUser.toString() : undefined }
  );

  return useMemo(() => {
    // If using a Virtual Recruiter, we don't need to check for Gmail auth status
    if (emailSenderOption === ListCampaignEmailSenderOptionEnum.VirtualRecruiter || isUsingShellAccount) {
      return { isAuthed: true, isLoading: false };
    }

    return {
      isAuthed:
        gmailAuthStatus?.valid === true &&
        !!emailAliasEmail &&
        !!emailAliasGmailCredentialAuthState &&
        [
          SlimEmailSenderAliasGmailCredentialAuthStateEnum.Valid,
          SlimEmailSenderAliasGmailCredentialAuthStateEnum.IsShellAccount,
          EmailSenderAliasGmailCredentialAuthStateEnum.Valid,
          EmailSenderAliasGmailCredentialAuthStateEnum.IsShellAccount,
        ].includes(emailAliasGmailCredentialAuthState),
      isLoading: isGmailAuthLoading,
    };
  }, [
    emailSenderOption,
    isUsingShellAccount,
    gmailAuthStatus?.valid,
    emailAliasEmail,
    emailAliasGmailCredentialAuthState,
    isGmailAuthLoading,
  ]);
};
